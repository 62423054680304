<template>
<div class="">

<div class="">
     <div class="page-banner">
         <img src="/dist/img/coach_banner.png" style="width:100%;" alt="page-banner">
         <div class="page-title">
            <h5 class="text-white">Award/Trophy Discounts</h5>
         </div>        
    </div>

       <div class="row">
            <div class="col-md-8 mb-3 mt-4">
               <div class="card card-body pt-0"> 
                   <div class="col-md-12 text-center">
                      <a target="_blank" href="https://www.wilsontrophy.com"><img src="/dist/img/waterski_logo.png" style="max-width:100px;" alt="wtc-banner"></a>
                   </div>
                  
                  <p class="mt-3">Coming Soon!</p>

               </div>
            </div>
            <div class="col-md-4 status_bar mt-4">
               <div class="right-sidebarlink">
                       <ul class="sidebarlink">
  <li><router-link class="" to="/club/club-insurance-information">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"><i class="far fa-address-card"></i> Club Insurance Information </h3>
                    </div>
                    </div>
            </router-link></li>
            <li><router-link class="" to="/club/quick-link-resources">

                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"> <i class="fas fa-dharmachakra"></i> Quick Link Resources</h3>
                    </div>
                    </div>
            </router-link></li>
            <li>
                <a target="_blank" class="" href="https://www.teamusa.org/usa-water-ski/safe-sport">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"> <i class="fas fa-dharmachakra"></i> Athlete Safety/Safe Sport</h3>
                    </div>
                    </div>
            </a>
            </li>
             <li><router-link class="" to="/club/practice-event-sanctioning">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"> <i class="fas fa-dharmachakra"></i> Practice and Event Sanctioning</h3>
                    </div>
                    </div>
            </router-link></li>
            <li>
                <router-link class="" to="/club/club-event-merchandise">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"><i class="fas fa-tags"></i> Club/Event Merchandise</h3>
                    </div>
                    </div>
            </router-link>
            </li>
            <li><router-link class="" to="/club/award-trophy-discounts">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"><i class="fas fa-tags"></i> Award/Trophy Discounts</h3>
                    </div>
                    </div>
            </router-link></li>
                     
                     
                 </ul>
                  <!-- <clubpagesidebarmenu></clubpagesidebarmenu> -->
               </div>
            </div>
         </div>

</div>

</div>
</template>


<script>

//import clubpagesidebarmenu from "./components/ClubPagesidebar";
export default {
    name: "awardtrophydiscounts",
     components: {},
      data(){
        return{
        }
    }
}
</script>